import React, { useState } from "react"

import Layout from "../layouts/Layout"
import SEO from "../components/SEO"

import Header from "../components/Header"
import Hero from "../components/Hero"
import Reviews from "../components/Reviews"
import Cards from "../components/Cards"
import HowItWorks from "../components/HowItWorks"
import Team from "../components/Team"
import Quotes from "../components/Quotes"
import Contact from "../components/Contact"
import Footer from "../components/Footer"

const IndexPage = () => {
  const [contactVisible, setContactVisible] = useState(false)

  const IOCallback = inView => {
    setContactVisible(inView)
  }

  return (
    <Layout>
      <SEO title="Focused Shockwave Therapy" />
      <Header contactVisible={contactVisible} />
      <Hero />
      <Reviews />
      <Cards />
      <HowItWorks />
      <Team />
      <Quotes />
      <Contact IOCallback={IOCallback} />
      <Footer />
    </Layout>
  )
}

export default IndexPage
