import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./Footer.module.scss"

const Footer = () => {
  const logo = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "logo" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `).allFile.edges[0].node.childImageSharp.fluid

  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <a
          className={styles.logo_link}
          href="https://featherstonpainclinic.co.nz/"
          rel="nopener noreferrer"
          target="_blank"
        >
          <Img
            alt="Featherston Street Pain Clinic logo"
            className={styles.logo}
            fluid={logo}
          />
        </a>
      </div>
    </footer>
  )
}

export default Footer
