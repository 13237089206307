import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AnchorLink from "react-anchor-link-smooth-scroll"

import cx from "classnames"
import styles from "./Header.module.scss"

const Header = ({ contactVisible }) => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const temp = window.pageYOffset

      setScrolled(temp >= window.innerHeight * 0.7)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const logo = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "logo" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `).allFile.edges[0].node.childImageSharp.fluid

  return (
    <header className={cx(styles.header)}>
      <div className={styles.wrapper}>
        <Img
          alt="Featherston Street Pain Clinic logo"
          className={styles.logo}
          fluid={logo}
        />
        <AnchorLink className={styles.cta} href="#contact-section">
          Book an Appointment
        </AnchorLink>
      </div>
      <div
        className={cx(
          styles.floating_bar,
          scrolled && contactVisible === false && styles.scrolled
        )}
      >
        <AnchorLink className={styles.floating_cta} href="#contact-section">
          Book an Appointment
        </AnchorLink>
      </div>
    </header>
  )
}

export default Header
