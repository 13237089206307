import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import AnchorLink from "react-anchor-link-smooth-scroll"

import styles from "./Hero.module.scss"

const Hero = () => {
  const image = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "hero" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `).allFile.edges[0].node.childImageSharp.fluid

  return (
    <BackgroundImage
      backgroundColor="#152f2e"
      className={styles.hero}
      fluid={image}
      tag="section"
    >
      <div className={styles.overlay}></div>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          Focused Shockwave Therapy
          <span className={styles.subtitle}>at Featherston St Pain Clinic</span>
        </h1>
        <p className={styles.intro}>
          Scientifically approved relief from stubborn pain – using the same
          safe non-invasive technology used to break up kidney stones.
        </p>
        <AnchorLink className={styles.cta} href="#contact-section">
          Book an Appointment
        </AnchorLink>
      </div>
    </BackgroundImage>
  )
}

export default Hero
