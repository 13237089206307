import React from "react"

import quoteIcon from "../images/quote-medium.svg"

import styles from "./Quotes.module.scss"

const Quotes = () => (
  <section className={styles.section}>
    <div className={styles.wrapper}>
      <h2 className={styles.heading}>
        What scientific journals are saying about Focused Shockwave Therapy
      </h2>
      <div className={styles.quotes}>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            The majority of published papers have shown positive and beneficial
            effects of using ESWT as a treatment for musculoskeletal disorders,
            with a success rate ranging from 65% to 91%
          </span>
          <p className={styles.attribute}>
            Loppolo et. al. The European Journal Of Rehabilitation Medicine 2014
          </p>
        </div>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            Treatment of tendon and muscle tissues was found to induce a
            long-time “tissue regeneration effect in addition to having a more
            immediate anthalgic and anti-inflammatory outcome
          </span>
          <p className={styles.attribute}>
            Mariotto et. al. Current Medicinal Chemistry 2009
          </p>
        </div>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            High-energy ESWT is effective for improving pain and shoulder
            function in chronic calcific shoulder tendinitis and can result in
            complete resolution of calcifications
          </span>
          <p className={styles.attribute}>
            Bannuru et. al. Annals of Internal Medicine 2014
          </p>
        </div>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            Shock wave therapy is a novel therapeutic modality without the need
            of surgery and surgical risks as well as surgical pain. It is
            convenient and cost-effective
          </span>
          <p className={styles.attribute}>
            Ching et. al. Chang Gung Medical Journal 2003
          </p>
        </div>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            ESWT is considered to be an alternative to surgery for several
            chronic tendinopathies and nonunions because of its efficacy,
            safety, and noninvasiveness
          </span>
          <p className={styles.attribute}>
            Moya et. al. Journal Of Bone & Joint Surgery 2018
          </p>
        </div>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            ESWT is a new non-invasive therapeutic modality with effectiveness,
            convenience and safety. ESWT has the potential of replacing surgery
            in many orthopedic disorders without the surgical risks
          </span>
          <p className={styles.attribute}>
            Ching Journal of Orthopedic Surgical Research 2012
          </p>
        </div>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            Extracorporeal shock wave therapy contributes to healing and pain
            reduction in plantar fasciitis
          </span>
          <p className={styles.attribute}>
            Babak et. al. Journal of Research in Medical Sciences 2012
          </p>
        </div>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            The results of the present study support the use of
            electromagnetically generated extracorporeal shockwave therapy for
            the treatment of refractory plantar heel pain
          </span>
          <p className={styles.attribute}>
            Gollwitzer et. al Journal of Foot and ankle surgery 2007
          </p>
        </div>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            88% of the treatment group were pain free or had good results. None
            of the placebo group were pain free, 33.3 % had good results
          </span>
          <p className={styles.attribute}>
            Abt et. al. Z Orthop Ihre Grenzgeb 2002
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Quotes
