import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./Cards.module.scss"

const Cards = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { regex: "/use-/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            name
          }
        }
      }
    }
  `).allFile.edges

  const imageShoulder = images.filter(
    image => image.node.name === "use-shoulder"
  )[0].node.childImageSharp.fluid
  const imageSole = images.filter(image => image.node.name === "use-sole")[0]
    .node.childImageSharp.fluid
  const imageHeel = images.filter(image => image.node.name === "use-heel")[0]
    .node.childImageSharp.fluid

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <Img className={styles.image} fluid={imageShoulder} />
          <div className={styles.content}>
            <h2 className={styles.heading}>Break Up Tendon Calcifications</h2>
            <p className={styles.text}>
              Numerous scientific studies have demonstrated Shockwave Therapy's
              ability to trigger reabsorption of shoulder tendon calcifications.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <Img className={styles.image} fluid={imageSole} />
          <div className={styles.content}>
            <h2 className={styles.heading}>Remodel Deep Scar Tissue</h2>
            <p className={styles.text}>
              Scar tissue caused by injuries and movement is part of many pain
              complaints. Shockwave Therapy may be beneficial for people with
              scar tissue.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <Img className={styles.image} fluid={imageHeel} />
          <div className={styles.content}>
            <h2 className={styles.heading}>
              Relieve Soft Tissue Aches & Pains
            </h2>
            <p className={styles.text}>
              Shockwave Therapy can help bring pain relief in a wide spectrum of
              painful conditions – from plantar fasciitis foot pain to tennis
              elbow and shoulder pain.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cards
