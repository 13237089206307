import React from "react"
import { BiConversation, BiClinic, BiRun } from "react-icons/bi"

import styles from "./HowItWorks.module.scss"

const HowItWorks = () => (
  <section className={styles.section}>
    <div className={styles.wrapper}>
      <div className={styles.callout}>
        <h2 className={styles.how_it_works}>How It Works</h2>
        <div className={styles.step}>
          <div className={styles.heading}>
            <BiConversation className={styles.icon} />
            <h3 className={styles.title}>
              <span className={styles.number}>Step 1</span>Initial Consult
            </h3>
          </div>
          <p className={styles.details}>
            30-45 mins duration – we aim to identified the cause of your pain
            and whether Shockwave Therapy is likely to be right for you. If it
            is we'll give you your first treatment too.
          </p>
        </div>
        <div className={styles.step}>
          <div className={styles.heading}>
            <BiClinic className={styles.icon} />
            <h3 className={styles.title}>
              <span className={styles.number}>Step 2</span>Treatment Schedule
            </h3>
          </div>
          <p className={styles.details}>
            Setup a treatment schedule – spaced out – up to 2 weeks apart. The
            majority of patients see significant improvements in their pain
            somewhere between their 2nd and their 6th visit. The duration of
            care needed depends heavily on how long you have had the issue.
          </p>
        </div>
        <div className={styles.step}>
          <div className={styles.heading}>
            <BiRun className={styles.icon} />
            <h3 className={styles.title}>
              <span className={styles.number}>Step 3 (Optional)</span>Rehab
              Exercises
            </h3>
          </div>
          <p className={styles.details}>
            Depending on the severity and duration of your issues, in some cases
            step 3 involves rehab exercises to strengthen the affected area.
            Some people also require an occasional ‘top up’ of Shockwave Therapy
            to keep their pain at bay. *
          </p>
        </div>
        <p className={styles.footnote}>
          * Focused shockwave therapy can work well as a compliment to any rehab
          or treatment you are already recieving with your physio or other
          practitioner. We can perform a trial of Shockwave Therapy
          collaboratively – while you continue to receive treatment with your
          primary practitioner.
        </p>
      </div>
    </div>
  </section>
)

export default HowItWorks
