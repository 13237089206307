import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./Team.module.scss"

const Team = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { regex: "/team-/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            name
          }
        }
      }
    }
  `).allFile.edges

  const hayley = images.filter(image => image.node.name === "team-hayley")[0]
    .node.childImageSharp.fluid
  const tobias = images.filter(image => image.node.name === "team-tobias")[0]
    .node.childImageSharp.fluid
  const charlie = images.filter(image => image.node.name === "team-charlie")[0]
    .node.childImageSharp.fluid

  return (
    <section className={styles.section}>
      <div className={styles.headings_wrapper}>
        <h2 className={styles.heading}>Meet Our Team</h2>
      </div>
      <div className={styles.photos}>
        <div className={styles.person}>
          <Img className={styles.image} fluid={hayley} />
          <h3 className={styles.name}>Hayley McKenzie</h3>
        </div>
        <div className={styles.person}>
          <Img className={styles.image} fluid={tobias} />
          <h3 className={styles.name}>Tobias Hall</h3>
        </div>
        <div className={styles.person}>
          <Img className={styles.image} fluid={charlie} />
          <h3 className={styles.name}>Charlie Rickard</h3>
        </div>
      </div>
      <div className={styles.who_are_we}>
        <h3 className={styles.heading}>And who are we?</h3>
        <p className={styles.text}>
          We are a team of allied health professionals with 20 years experience
          in pain management – 12 of which have been spent serving the
          Wellington community.
        </p>
        <p className={styles.text}>
          We are proud to report that we are the only clinic on the lower north
          island (and one of only 3 clinics in the whole of NZ) who are offering
          combined Radial & Focused Shockwave Treatments for pain management.
          Having both Radial Shockwave and Focused Shockwave machines enables us
          to treat a broad spectrum of pain complaints – and often enables us to
          get outcomes with far fewer visits.
        </p>
        <a
          className={styles.link}
          href="https://featherstonpainclinic.co.nz/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Learn More About Us
        </a>
      </div>
    </section>
  )
}

export default Team
