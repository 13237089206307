import React from "react"
import { BiRightArrowAlt } from "react-icons/bi"
import { MdStar } from "react-icons/md"

import quoteIcon from "../images/quote-dark.svg"

import styles from "./Reviews.module.scss"

const Reviews = () => (
  <section className={styles.section}>
    <div className={styles.wrapper}>
      <div className={styles.callout}>
        <div className={styles.reviews}>
          <div className={styles.stars}>
            <MdStar className={styles.star} />
            <MdStar className={styles.star} />
            <MdStar className={styles.star} />
            <MdStar className={styles.star} />
            <MdStar className={styles.star} />
          </div>
          <p className={styles.text}>
            Check out our 100+ 5-star reviews on Google!
          </p>
          <a
            className={styles.link}
            href="https://www.google.com/search?q=featherston+st+pain+clinic#lrd=0x6d38af6c51d041d5:0x1c89ec5c00c2eea8,1,,,"
            rel="noopener noreferrer"
            target="_blank"
          >
            Read our Google reviews here{" "}
            <BiRightArrowAlt className={styles.arrow} />
          </a>
        </div>
        <div className={styles.quote}>
          <img alt="" className={styles.icon} src={quoteIcon} />
          <span className={styles.text}>
            ESWT is a new non-invasive therapeutic modality with effectiveness,
            convenience and safety. ESWT has the potential of replacing surgery
            in many orthopedic disorders without the surgical risks.
          </span>
          <p className={styles.attribute}>
            Ching, Journal of Orthopedic Surgical Research 2012
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Reviews
