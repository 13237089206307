import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"

import cx from "classnames"
import styles from "./Contact.module.scss"

const Contact = ({ IOCallback }) => {
  const clinic = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "clinic" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `).allFile.edges[0].node.childImageSharp.fluid

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    IOCallback(inView)
  })

  const [formState, setFormState] = useState("unsubmitted")
  const [buttonText, setButtonText] = useState("Submit")

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  })

  const handleSubmit = async event => {
    event.preventDefault()

    setButtonText("Submitting...")

    const encode = data => {
      return Object.keys(data)
        .map(
          key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
    }

    await fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({ "form-name": "contact-form", ...formData }),
    })
      .then(() => {
        setTimeout(() => {
          setButtonText("Thanks for contacting us! We'll be in touch soon.")
          setFormState("submitted")
        }, 1500)
      })
      .then(console.log(encode({ "form-name": "contact-form", ...formData })))
  }

  return (
    <section className={styles.section} id="contact-section">
      <div className={styles.wrapper}>
        <Img
          alt="Featherston Street Pain Clining"
          className={styles.image}
          fluid={{ ...clinic, aspectRatio: 16 / 10 }}
        />
        <div className={styles.content}>
          <h2 className={styles.heading}>Enquire Today</h2>
          <p className={styles.intro}>
            Send us a message and we'll get back to you within 24 hours to
            arrange an appointment.
          </p>
          <p className={styles.phone}>
            Alternatively, call us on <a href="tel:043856446">04 385 6446</a>,
            or text the clinic mobile on 027 608 8083 with any questions.
          </p>
          <form
            className={styles.form}
            data-netlify-honeypot="bot-field"
            id="contact-form"
            method="POST"
            name="contact-form"
            netlify="true"
            onSubmit={handleSubmit}
            ref={ref}
          >
            <div className={cx(styles.group, styles.half)}>
              <label className={styles.label} htmlFor="name">
                Your Name
              </label>
              <input
                aria-label="Your Name"
                className={styles.input}
                disabled={formState === "submitted" && true}
                id="name"
                name="name"
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
                required
                type="text"
              />
            </div>
            <div className={cx(styles.group, styles.half)}>
              <label className={styles.label} htmlFor="phone">
                Phone Number
              </label>
              <input
                aria-label="Phone Number"
                className={styles.input}
                disabled={formState === "submitted" && true}
                id="phone"
                name="phone"
                onChange={e =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                required
                type="text"
              />
            </div>
            <div className={styles.group}>
              <label className={styles.label} htmlFor="email">
                Email Address
              </label>
              <input
                aria-label="Email Address"
                className={styles.input}
                disabled={formState === "submitted" && true}
                id="email"
                name="email"
                onChange={e =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
                type="email"
              />
            </div>
            <div className={styles.group}>
              <label className={styles.label} htmlFor="message">
                Your Message
              </label>
              <textarea
                aria-label="Your Message"
                className={cx(styles.input, styles.textarea)}
                disabled={formState === "submitted" && true}
                id="message"
                name="message"
                onChange={e =>
                  setFormData({ ...formData, message: e.target.value })
                }
                required
              />
            </div>
            <div className={styles.group}>
              <input
                className={styles.submit}
                disabled={formState === "submitted" && true}
                type="submit"
                value={buttonText}
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact
